import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell de fins a 10 cm de diàmetre, convex, de colors variables, de blau verdós fins a un gris blavós. El centre sol ser de color marró cremós o decolorat. El peu en canvi és blanquinós i cilíndric. Les làmines són atapeïdes de color blanc. Les espores són de color crema en massa, el·lipsoïdals i amb berrugues, de 6-9 x 5,5-6,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      